import React from 'react';
import {formatTime} from '../../utils/time';

class Duration extends React.Component
{
    constructor(props)
    {
        super(props);

        this.translate = props.translate;
        this.state     = {player: props.playerState.props()};
        props.playerState.on(`change`, () => {
            this.setState({player: props.playerState.props()});
        });
    }

    renderCountdown()
    {
        if (this.state.player.adCountdown) {
            return (
                <span className="playernow-time-ad-countdown"> ∙ {this.state.player.adCountdown} {this.translate.L(`SEC`)}.</span>
            );
        }

        return null;
    }

    render()
    {
        if (!this.state.player.duration) {
            return null;
        }

        if (this.state.player.isDVR) {
            if (this.state.player.duration <= 0) {
                return null;
            }

            let duration    = this.state.player.duration;
            let currentTime = this.state.player.currentTime - this.state.player.seekRangeStart;

            if (100 - 100*currentTime/duration < 5 || duration === Infinity) {
                return (
                    <div className="playernow-action-block playernow-time-progress">
                        <span className="playernow-time-live">{this.translate.L(`ON_AIR`)}</span>
                    </div>
                );
            }

            return (
                <div className="playernow-action-block playernow-time-progress">
                    <span className="playernow-time-current">{formatTime(currentTime)}</span>
                </div>
            );
        }

        if (this.state.player.isStream) {
            return (
                <div className="playernow-action-block playernow-time-progress">
                    <span className="playernow-time-live">{this.translate.L(`ON_AIR`)}</span>
                </div>
            );
        }

        if (this.state.player.adPlaying && this.state.player.adPlaying.toLowerCase() === `linear`) {
            return (
                <div className="playernow-action-block playernow-time-progress">
                    <span className="playernow-time-ad-text">{this.translate.L(`AD`)}</span>
                    {this.renderCountdown()}
                </div>
            );
        }

        return (
            <div className="playernow-action-block playernow-time-progress">
                <span className="playernow-time-current">{formatTime(this.state.player.currentTime)}</span>
                <span className="playernow-time-delimiter"> / </span>
                <span className="playernow-time-duration">{formatTime(this.state.player.duration)}</span>
            </div>
        );
    }
}

export default Duration;

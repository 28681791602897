import DashShaka from './protocol/dash/shaka';
import Mpeg from './protocol/mpeg';
import NativeHls from './protocol/hls/native-hls';
import HlsShaka from './protocol/hls/shaka';
import * as client from './../../utils/client';

class Factory
{
    static createProtocol(video, options, eventBus, logger, drm)
    {
        if (options.playback.hls && options.playback.hls.url && options.playback.hls.prefer) {
            let protocol = Factory.createHlsProtocol(video, options, eventBus, logger, drm);
            if (protocol) {
                return {protocol: protocol, url: options.playback.hls.url};
            }
        }

        if (options.playback.dash && options.playback.dash.url) {
            let protocol = Factory.createDashProtocol(video, options, eventBus, logger, drm);
            if (protocol) {
                return {protocol: protocol, url: options.playback.dash.url};
            }
        }

        if (options.playback.hls && options.playback.hls.url) {
            let protocol = Factory.createHlsProtocol(video, options, eventBus, logger, drm);
            if (protocol) {
                return {protocol: protocol, url: options.playback.hls.url};
            }
        }

        if (options.playback.mp4 && options.playback.mp4.url) {
            return {protocol: Factory.createMP4Protocol(video, options, eventBus, logger), url: options.playback.mp4.url};
        }

        return {protocol: null, url: ''};
    }

    static createHlsProtocol(video, options, eventBus, logger, drm)
    {
        logger.info(`[Playback] Trying to init HLS`);

        if (
            options.playback.hls.drm &&
            !drm.isFairPlaySupported()
        ) {
            logger.info(`[Playback] HLS DRM exists and DRM FairPlay not supported`);
            return null;
        }

        options.playback.hls.scteEnabled = !!(options.adv && options.adv.scte);

        if (options.dvr.enabled && client.isAppleDevice() && NativeHls.isSupported()) {
            // HlsShaka не поддерживает выбор качеств при нативном воспроизведении
            // https://github.com/shaka-project/shaka-player/issues/4285
            logger.info(`[Playback] Trying to init DVR NativeHls`);
            options.playback.hls.dvr = options.dvr;
            return new NativeHls(video, options.playback.hls, eventBus, logger);
        }

        if (options.dvr.enabled && HlsShaka.isSupported()) {
            logger.info(`[Playback] Trying to init DVR HLS Shaka ` + HlsShaka.version());
            options.playback.hls.dvr = options.dvr;
            return new HlsShaka(video, options.playback.hls, eventBus, logger);
        }

        if (client.isAppleDevice() && NativeHls.isSupported()) {
            // HlsShaka не поддерживает выбор качеств при нативном воспроизведении
            // https://github.com/shaka-project/shaka-player/issues/4285
            logger.info(`[Playback] Trying to init NativeHls`);
            return new NativeHls(video, options.playback.hls, eventBus, logger);
        }

        if (HlsShaka.isSupported()) {
            logger.info(`[Playback] Trying to init HLS Shaka ` + HlsShaka.version());
            return new HlsShaka(video, options.playback.hls, eventBus, logger);
        }

        logger.info(`[Playback] NativeHls/HLS Shaka not supported`);

        return null;
    }

    static createDashProtocol(video, options, eventBus, logger, drm)
    {
        logger.info(`[Playback] Trying to init DASH`);

        if (
            options.playback.dash.drm &&
            !drm.isPlayReadySupported() &&
            !drm.isWidevineSupported()
        ) {
            logger.info(`[Playback] DASH DRM exists and DRM PlayReady/Widevine not supported`);
            return null;
        }

        if (!DashShaka.isBrowserSupported()) {
            logger.info(`[Playback] DASH Shaka not supported`);
            return null;
        }

        logger.info(`[Playback] Trying to init DASH Shaka ` + DashShaka.version());
        return new DashShaka(video, options.playback.dash, eventBus, logger);
    }

    static createMP4Protocol(video, options, eventBus, logger)
    {
        logger.info(`[Playback] Trying to init MP4`);
        return new Mpeg(video, options.playback.mp4, eventBus, logger);
    }
}

export default Factory;

import React from 'react';
import {formatTime} from '../../utils/time';

class TimeLine extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {player: props.playerState.props()};

        props.playerState.on(`change`, () => {
            this.setState({player: props.playerState.props()});
        });

        this.lastSlide = Date.now();

        this.handleMouseDown  = this.handleMouseDown.bind(this);
        this.handleMouseUp    = this.handleMouseUp.bind(this);
        this.handleMouseSeek  = this.handleMouseSeek.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.showTooltip      = this.showTooltip.bind(this);

        this.progressTooltip = null;
        this.progressLine    = null;
    }

    handleMouseDown(e)
    {
        e.preventDefault();

        document.addEventListener(`mouseup`, this.handleMouseUp);
        document.addEventListener(`mousemove`, this.handleMouseSeek);

        const {seconds} = this.getPositionFromEvent(e);
        this.setState({
            seconds,
            sliding: true,
        });
    }

    handleMouseUp()
    {
        if (this.state.sliding && this.state.seconds && this.state.player.adPlaying !== `linear`) {
            this.props.controller.seek(this.state.seconds);
        }
        this.setState({
            seconds:           null,
            sliding:           false,
            tooltipText:       null,
            tooltipLeftOffset: null,
        });

        document.removeEventListener(`mouseup`, this.handleMouseUp);
        document.removeEventListener(`mousemove`, this.handleMouseSeek);
    }

    showTooltip(e)
    {
        const {seconds, timeLineWidth, offsetLeft} = this.getPositionFromEvent(e);
        if (!seconds) {
            return;
        }

        const tooltipWidth    = this.progressTooltip.clientWidth;
        let tooltipLeftOffset = offsetLeft - tooltipWidth / 2;
        tooltipLeftOffset     = Math.max(0, Math.min(timeLineWidth - tooltipWidth, tooltipLeftOffset));

        this.setState({
            tooltipText: formatTime(seconds),
            tooltipLeftOffset,
            seconds,
        });
    }

    handleMouseSeek(e)
    {
        const {seconds} = this.getPositionFromEvent(e);
        if (!seconds) {
            return;
        }

        this.setState({
            seconds,
        });

        // throttling
        if (this.state.sliding && Date.now() - this.lastSlide > 75) {
            this.lastSlide = Date.now();
            this.props.controller.seek(this.state.seconds);
        }
    }

    handleMouseLeave()
    {
        this.setState({
            tooltipText:       null,
            tooltipLeftOffset: null,
        });
    }

    getPositionFromEvent(e)
    {
        if (this.state.player.adPlaying === `linear`) {
            return {};
        }

        const handle = this.progressLine;
        const offset = handle.getBoundingClientRect().left;
        const left   = e.clientX - offset;

        let seconds;
        if (this.state.player.isStream) {
            seconds = this.state.player.bufferedDuration * left / handle.clientWidth;
            seconds += this.state.player.bufferedFrom;
        } else {
            seconds = this.state.player.duration * left / handle.clientWidth;
            if (seconds < 0) {
                seconds = 0;
            }
        }

        return {
            seconds,
            timeLineWidth: handle.clientWidth,
            offsetLeft:    left,
        };
    }

    onFocus(event)
    {
        event.preventDefault();
        this.props.playerState.set(`focusElement`, `TimeLine`);
    }

    onBlur(event)
    {
        event.preventDefault();
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'TimeLine') {
            return;
        }

        switch (e.keyCode) {
            case 37:
            case 40:
                e.preventDefault();
                this.props.changeCurrentTime(-10);
                break;
            case 38:
            case 39:
                e.preventDefault();
                this.props.changeCurrentTime(10);
                break;
        }
    }

    render()
    {
        if (this.state.player.isStream) {
            return null;
        }

        let width = 100 * (this.state.player.currentTime - this.state.player.seekRangeStart) / this.state.player.duration;

        let bufferedWidth = 0;
        if (!this.state.player.adPlaying) {
            bufferedWidth = 100 * this.state.player.bufferedTo / this.state.player.duration;
        }

        return (
            <div className="playernow-progress-wrap"
                 onMouseDown={this.handleMouseDown}
                 onMouseLeave={this.handleMouseLeave}
                 onMouseMove={this.showTooltip}
            >
                <div className={'playernow-progress-tooltip' + (this.state.tooltipText
                    ? ' playernow-visible'
                    : '')}
                     ref={(progressTooltip) => this.progressTooltip = progressTooltip}
                     style={{left: this.state.tooltipLeftOffset}}>
                    <span className="playernow-progress-tooltip-text">{this.state.tooltipText}</span>
                </div>
                <div className="playernow-progress-line"
                     tabIndex="0"
                     onFocus={(e) => this.onFocus(e)}
                     onBlur={(e) => this.onBlur(e)}
                     onKeyDown={(e) => this.onKeyDown(e)}
                     ref={(progressLine) => this.progressLine = progressLine}>
                    <span className="playernow-progress-current" style={{width: `${width}%`}}/>
                    <span className="playernow-progress-buffer" style={{width: `${bufferedWidth}%`}}/>
                </div>
            </div>
        );
    }
}

export default TimeLine;

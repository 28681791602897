import muxjs from 'mux.js';
import * as err from '../../../../utils/err';
import shaka from 'shaka-player';
import libPkg from 'shaka-player/package.json';
import AudioTrack from '../../track/audio-track';
import VideoTrack from '../../track/video-track';
import Protocol from '../protocol';
import {isEdge} from '../../../../utils/client';

window.muxjs = muxjs;

const FAIRPLAY_KEY_SYSTEM = `com.apple.fps.1_0`;

export default class HlsShaka extends Protocol
{
    static type = `HlsShaka`;
    #hls;

    constructor(video, options, eventBus, logger)
    {
        super(HlsShaka.type, video, options, eventBus, logger);
    }

    get duration()
    {
        if (this.options.dvr && this.options.dvr.enabled) {
            return this.#hls.seekRange().end - this.#hls.seekRange().start;
        }

        return this.video.duration;
    }

    get seekRangeStart()
    {
        if (!this.isInitialized()) {
            return 0;
        }

        return this.#hls.seekRange().start;
    }

    get seekRangeEnd()
    {
        if (!this.isInitialized()) {
            return this.video.duration;
        }

        return this.#hls.seekRange().end;
    }

    static isSupported()
    {
        return shaka.Player.isBrowserSupported();
    }

    static version()
    {
        return libPkg.version;
    }

    #bindEvents()
    {
        this.#hls.addEventListener('error', this.handleError.bind(this));
        this.#hls.addEventListener(`urn:scte:scte35:2013:xml`, this.handleSCTE.bind(this));
        this.#hls.addEventListener(`urn:scte:scte35:2014:xml`, this.handleSCTE.bind(this));
        this.#hls.addEventListener(`urn:scte:scte35:2015:xml`, this.handleSCTE.bind(this));
        this.#hls.addEventListener(`urn:scte:scte35:2016:xml`, this.handleSCTE.bind(this));
    }

    #unbindEvents()
    {
        this.#hls.removeEventListener('error', this.handleError.bind(this));
        this.#hls.removeEventListener(`urn:scte:scte35:2013:xml`, this.handleSCTE.bind(this));
        this.#hls.removeEventListener(`urn:scte:scte35:2014:xml`, this.handleSCTE.bind(this));
        this.#hls.removeEventListener(`urn:scte:scte35:2015:xml`, this.handleSCTE.bind(this));
        this.#hls.removeEventListener(`urn:scte:scte35:2016:xml`, this.handleSCTE.bind(this));
    }

    onUpdatedMetadata()
    {
        this.eventBus.emit(`playback.metadata`, {
            duration:       this.duration,
            seekRangeStart: this.#hls.seekRange().start,
            seekRangeEnd:   this.#hls.seekRange().end,
        });
    }

    handleSCTE(e)
    {
        if (!e.event) {
            return;
        }
        this.eventBus.emit(`playback.SCTE35`, {options: e.event});
    }

    handleError(e)
    {
        if (!e) {
            return;
        }

        this.eventBus.emit(`playback.error`, {type: err.list.hlsLibraryError, data: e});
    }

    updateVideoTracks()
    {
        const bitrateList = this.#hls.getVariantTracks();

        let uniqueIds             = [];
        const uniqueVariantTracks = bitrateList.filter(level => {

            if (uniqueIds.includes(level.videoId)) {
                return false;
            }

            uniqueIds.push(level.videoId);

            return true;
        });

        let heights = uniqueVariantTracks.map((level) => {
            return level.height;
        });

        let hasDuplicateHeights = uniqueVariantTracks.length !== (new Set(heights)).size;

        const videoTracks = uniqueVariantTracks.map(level => {

            let label = level.height;
            if (hasDuplicateHeights && level.bandwidth) {
                label += ' (' + level.bandwidth + ')';
            }

            return new VideoTrack(level.videoId, level.height, label);
        });

        videoTracks.sort(this.sortVideoTracks);
        videoTracks.push(new VideoTrack(-1, 0, `auto`));
        this.eventBus.emit(`playback.videoTracks`, {videoTracks});
    }

    updateAudioTracks()
    {
        const bitrateList = this.#hls.getVariantTracks();

        let uniqueIds             = [];
        const uniqueVariantTracks = bitrateList.filter(level => {

            if (uniqueIds.includes(level.audioId)) {
                return false;
            }

            uniqueIds.push(level.audioId);

            return true;
        });

        let audioTracks = uniqueVariantTracks.map((level) => {
            let label = level.label ?? level.language;
            return new AudioTrack(level.audioId, level.language, label);
        });

        this.eventBus.emit(`playback.audioTracks`, {audioTracks});
    }

    updateActiveAudioTrack()
    {
        let track = this.#hls.getVariantTracks().find(track => track.active);
        if (track) {
            this.eventBus.emit(`playback.activeAudioTrackId`, {activeAudioTrackId: track.audioId});
        }
    }

    async setVideoTrack(track)
    {
        if (track.id === -1) {
            this.#hls.configure({abr: {enabled: true}});

            return true;
        }

        this.#hls.configure({abr: {enabled: false}});

        const tracks = this.#hls.getVariantTracks();

        let activeVariant = tracks.find(t => t.active);
        let variant       = tracks.find(t => t.videoId === track.id && t.audioId === activeVariant.audioId);

        if (!variant) {
            this.logger.error(`[Playback][HLS] Invalid video trackId: ` + track.id);
            return true;
        }

        this.#hls.selectVariantTrack(variant, true);

        return true;
    }

    async setAudioTrack(track)
    {
        const tracks = this.#hls.getVariantTracks();

        let activeVariant = tracks.find(t => t.active);
        let variant       = tracks.find(t => t.audioId === track.id && t.videoId === activeVariant.videoId);

        if (!variant) {
            this.logger.error(`[Playback][HLS] Invalid audio trackId: ` + track.id);
            return true;
        }

        this.#hls.selectVariantTrack(variant, true);

        return true;
    }

    async initialize(activeVideoTrackId = -1)
    {
        return new Promise(resolve => {

            this.logger.debug(`[Playback][HLS Shaka] Initializing...`);

            this.#hls = new shaka.Player(this.video);
            if (this.options.dvr && this.options.dvr.enabled) {
                this.#hls.configure({abr: {enabled: false}, manifest: {availabilityWindowOverride: Math.abs(parseInt(this.options.dvr.duration))}});
            } else {
                this.#hls.configure({abr: {enabled: false}});
            }

            if (isEdge()) {
                this.#hls.configure('streaming.forceTransmuxTS', true);
            }

            this.#hls.addEventListener('trackschanged', this.updateVideoTracks.bind(this));
            this.#hls.addEventListener('trackschanged', this.updateAudioTracks.bind(this));
            this.#hls.addEventListener('adaptation', this.updateActiveAudioTrack.bind(this));
            this.#hls.addEventListener('metadata', this.onUpdatedMetadata.bind(this));
            this.#bindEvents();

            if (!this.options.drm) {
                this.#hls.load(this.options.url)
                    .then(() => {
                        this.initialized = true;
                        this.logger.debug(`[Playback][HLS Shaka] Initialized`);
                        resolve();
                    })
                    .catch(this.handleError.bind(this));

                return;
            }

            let fairplayOptions = this.options.drm.keySystems[FAIRPLAY_KEY_SYSTEM];

            let serverCertificatePath = fairplayOptions.certificateUrl;
            let serverProcessSPCPath  = fairplayOptions.serverURL;

            let contentIdIn = '';

            this.logger.debug('[Playback][HLS] Request certificate', serverCertificatePath);
            fetch(serverCertificatePath)
                .then(req => {
                    this.logger.debug('[Playback][HLS] Request certificate done');
                    return req.arrayBuffer();
                })
                .then((cert) => {
                    this.logger.debug('[Playback][HLS] Configure Shaka HLS');
                    this.#hls.configure({
                        drm: {
                            servers:           {
                                'com.apple.fps.1_0': serverProcessSPCPath,
                            },
                            advanced:          {
                                'com.apple.fps.1_0': {
                                    serverCertificate: new Uint8Array(cert),
                                },
                            },
                            initDataTransform: function(initData, initDataType, _cert) {
                                this.logger.debug('[Playback][HLS] initDataTransform', initData, initDataType, _cert);
                                if (initDataType !== 'skd') {
                                    return initData;
                                }

                                // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
                                const skdUri    = shaka.util.StringUtils.fromBytesAutoDetect(initData);
                                const contentId = getMyContentId(skdUri);
                                contentIdIn     = contentId;

                                const cert = this.#hls.drmInfo().serverCertificate;
                                this.logger.debug('[Playback][HLS] SKD', skdUri, contentId);
                                return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
                            }.bind(this),
                        },
                    });

                    this.#hls.getNetworkingEngine().registerRequestFilter((type, request) => {
                        if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
                            return;
                        }

                        const originalPayload = new Uint8Array(request.body);
                        const base64Payload   = shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);

                        this.logger.debug('[Playback][HLS] License Request', type, request);

                        let params = '{ "content_id": "' + encodeURIComponent(contentIdIn) + '",' +
                            '  "spc_message": "' + base64Payload + '" }';

                        request.headers['Content-Type'] = 'application/json';
                        request.body                    = params;
                    });

                    let getMyContentId = function(sdkUri) {
                        const url = new URL(sdkUri);
                        return url.hostname;
                    };

                    this.#hls.getNetworkingEngine().registerResponseFilter((type, response) => {
                        if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
                            return;
                        }

                        const data = JSON.parse(shaka.util.StringUtils.fromUTF8(response.data));
                        this.logger.debug('[Playback][HLS] License Response', response, data);

                        if (data.operation_status_code === 0) {
                            response.data = shaka.util.Uint8ArrayUtils.fromBase64(data.ckc_message);
                        } else {
                            throw 'Bad response status';
                        }
                    });

                    this.logger.debug('[Playback][HLS] Configure Shaka HLS done');

                    this.#hls.load(this.options.url)
                        .catch(this.handleError.bind(this));

                    this.initialized = true;
                    this.logger.debug(`[Playback][HLS Shaka] Initialized`);
                    resolve();

                }).catch((e) => {
                this.logger.error(e);
            });
        });
    }

    static isMasterPlaylist(playlist)
    {
        return /^#EXT-X-STREAM-INF:?(.*)[\n\r]{1,}(.*)$/gmi.test(playlist);
    }

    static buildMasterPlaylistWithMedia(url)
    {
        return '#EXTM3U\n' +
            '#EXT-X-STREAM-INF:PROGRAM-ID=1,BANDWIDTH=600000,RESOLUTION=100x100\n' +
            url;
    }

    setPlaylistUrl(url)
    {
        this.options.url = url;
    }

    async destroy()
    {
        this.#unbindEvents();
        await this.#hls.unload();
        await this.#hls.destroy();
    }

    seek(pos)
    {
        this.onUpdatedMetadata();
        this.video.currentTime = this.#hls.seekRange().start + pos;
    }
}
